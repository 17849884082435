/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Icon } from './index';

const Tag = ({ active, className, icon, iconRight, label, disabled, kind = 'neutral', size = 'medium', onRemove }) => {
  const removeHandler = () => {
    if (onRemove && !disabled) {
      onRemove();
    }
  };
  return (
    <div
      className={cs('Tag', className, {
        [`Tag--${kind}`]: kind,
        [`Tag--${size}`]: size,
        active,
        disabled,
      })}
      tabIndex={0}
    >
      {icon && <Icon kind={icon} inheritColor />}
      {label}
      {iconRight && <Icon kind={iconRight} inheritColor />}
      {onRemove && (
        <Icon
          kind="close"
          inheritColor
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          onClick={removeHandler}
        />
      )}
    </div>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconRight: PropTypes.node,
  disabled: PropTypes.bool,
  kind: PropTypes.oneOf(['neutral', 'primary', 'positive', 'warning', 'attention', 'informative']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'large', 'extraLarge']),
};

export default Tag;
