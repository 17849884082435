/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Icon, Badge } from '../index';

const Tabs = ({ tabs, activeTab, onTabSelect, direction = 'bottom', variant = 'underline', testId }) => (
  <div className={cs('Tabs-container', `Tabs-container--variant-${variant}`)}>
    {tabs.map(tab => (
      <div
        tabIndex={0}
        key={tab.value}
        className={cs('Tabs-item', `Tabs-item--direction-${direction}`, {
          active: activeTab === tab.value,
        })}
        onClick={() => onTabSelect(tab.value)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            onTabSelect(tab.value);
          }
        }}
        data-test-id={testId}
      >
        {tab.icon && <Icon size="20px" kind={tab.icon} />}
        {tab.label}
        {tab.count && (
          <span style={{ marginLeft: '6px' }}>
            <Badge kind="lightGray" size="medium" count={tab.count} />
          </span>
        )}
      </div>
    ))}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['bottom', 'top']),
  variant: PropTypes.oneOf(['underline', 'content']),
  testId: PropTypes.string,
};

export default Tabs;
