/* eslint-disable prefer-spread */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import { createBrowserHistory } from 'history';
import { Button, Row, SimpleTooltip } from './index';
import { t } from '../i18n';

export const PER_OPTIONS = [5, 10, 20];

export const usePagination = defaultOptions => {
  const [pageSize, setPageSize] = useState(defaultOptions?.pageSize || PER_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(defaultOptions?.currentPage || 0);
  const [pagInfo, setPagInfo] = useState({ totalItems: defaultOptions?.totalItems || 0 });

  useEffect(() => {
    setCurrentPage(0);
  }, [pageSize]);

  const setPaginationInfo = useCallback(
    params => {
      setPagInfo(prev => ({ ...prev, ...params }));
    },
    [setPagInfo]
  );

  const result = useMemo(
    () => ({
      setPaginationInfo,
      pagination: {
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        maxPages: Math.floor(pagInfo.totalItems / pageSize),
        total: pagInfo.totalItems,
      },
    }),
    [pageSize, setPageSize, pagInfo, currentPage, setCurrentPage, setPaginationInfo]
  );

  return result;
};

const PAGINATION_VARIANTS = {
  classic: {
    stackCount: 9,
    oneCutSideStackCount: 7,
    twoCutSidesStackCount: 5,
    cutIndex: 5,
    step: 2,
  },
  minified: {
    stackCount: 7,
    oneCutSideStackCount: 5,
    twoCutSidesStackCount: 3,
    cutIndex: 3,
    step: 1,
  },
};

const history = createBrowserHistory();

export const getPageFromUrl = () => {
  if (/page=/.test(window.location.hash)) {
    const page = parseInt((window.location.hash || '').replace('#page=', '') || '0', 10);
    if (page === 0) {
      return 0;
    }
    return page - 1;
  }

  return 0;
};

export const setPageToUrl = page => {
  window.location.hash = `page=${page + 1}`;
};

const ArrowButton = ({ className, text, tooltip, size, ...rest }) => (
  <span className={className} style={{ display: 'inline-block' }}>
    <SimpleTooltip text={!text ? tooltip : ''}>
      <Button size={size} onlyIcon={!text} kind="secondary" {...rest}>
        {text}
      </Button>
    </SimpleTooltip>
  </span>
);

ArrowButton.propTypes = {
  className: RPT.string,
  icon: RPT.string,
  text: RPT.string,
  tooltip: RPT.string,
  size: RPT.string,
};

const Pagination = ({
  className,
  current,
  disableAnchor,
  changePage,
  children,
  isCompact = false,
  maxPages,
  hideDisabledControls = false,
  variant = 'minified',
  ...rest
}) => {
  const changePageDebounceTimeout = useRef(null);

  const changePageCallback = useCallback(
    (wantedPage, overrideUrl = true) => () => {
      if (!disableAnchor && overrideUrl) {
        setPageToUrl(wantedPage);
      }
      if (current !== wantedPage && typeof changePage === 'function') {
        changePage(wantedPage)();
      }
    },
    [current, changePage, disableAnchor]
  );

  const resolveCurrentPage = useCallback(() => {
    if (maxPages === 0) return null;
    if (disableAnchor) return current;

    let currentPage = getPageFromUrl();
    if (maxPages - 1 < currentPage) {
      currentPage = maxPages - 1;
    }

    if (currentPage !== current) {
      if (changePageDebounceTimeout.current) {
        clearTimeout(changePageDebounceTimeout.current);
      }
      changePageDebounceTimeout.current = setTimeout(changePageCallback(currentPage, false), 200);
    }

    return currentPage;
  }, [current, maxPages, disableAnchor, changePageCallback]);

  useEffect(() => {
    const unlisten = history.listen(resolveCurrentPage);
    return () => {
      if (unlisten) unlisten();
    };
  }, [history, resolveCurrentPage]);

  const currentPage = resolveCurrentPage();
  let pages = [];
  let startingCounter = 0;
  let firstMore = false;
  let lastMore = false;
  const pagVars = PAGINATION_VARIANTS[variant];
  const buttonSize = isCompact ? 'small' : 'medium';

  if (maxPages > pagVars.stackCount) {
    pages = Array(pagVars.oneCutSideStackCount).fill(null);
    firstMore = false;
    lastMore = true;

    if (currentPage >= pagVars.cutIndex) {
      firstMore = true;
      pages = Array(pagVars.twoCutSidesStackCount).fill(null);
      startingCounter = currentPage - pagVars.step;

      if (currentPage + pagVars.cutIndex >= maxPages) {
        lastMore = false;
        pages = Array(pagVars.oneCutSideStackCount).fill(null);
        startingCounter = maxPages - pagVars.oneCutSideStackCount;
      }
    }
  } else {
    pages = Array(maxPages).fill(null);
  }

  return (
    <div
      className={cs('Pagination', className, {
        'Pagination--onlyButtons': !children,
        'Pagination--compact': isCompact,
      })}
      {...rest}
    >
      {!!children && (
        <div className="Pagination-items">
          <Row center inline>
            {children}
          </Row>
        </div>
      )}
      {maxPages > 1 && (
        <div className="Pagination-buttons" data-test-id="pagination">
          {(!hideDisabledControls || currentPage > 0) && (
            <>
              <ArrowButton
                className="mr-12"
                data-test-id="pagination-first"
                disabled={currentPage === 0}
                icon="chevron-first"
                key="first"
                kind="secondary"
                onClick={changePageCallback(0)}
                size={buttonSize}
                tooltip={t('views.pagination.first_page', { default: 'First page' })}
              />
              <ArrowButton
                className={isCompact ? 'mr-16' : 'mr-24'}
                data-test-id="pagination-prev"
                disabled={currentPage === 0}
                icon="chevron-left"
                key="previous"
                kind="secondary"
                onClick={changePageCallback(currentPage - 1)}
                size={buttonSize}
                text={isCompact ? '' : t('views.pagination.prev_button', { default: 'Prev' })}
                tooltip={t('views.pagination.previous_page')}
              />
            </>
          )}
          {firstMore && (
            <>
              <Button
                className="Pagination-button"
                data-test-id="pagination-current-1"
                key="1"
                onClick={changePageCallback(0)}
                size={buttonSize}
              >
                1
              </Button>
              <Button key="firstMore" className="Pagination-button notActive" disabled size={buttonSize}>
                …
              </Button>
            </>
          )}
          {pages.map((_, index) => (
            <Button
              data-test-id={`pagination-current-${startingCounter + index + 1}`}
              key={startingCounter + index + 1}
              className={cs('Pagination-button', { active: currentPage === startingCounter + index })}
              onClick={changePageCallback(startingCounter + index)}
              size={buttonSize}
            >
              {startingCounter + index + 1}
            </Button>
          ))}
          {lastMore && (
            <>
              <Button key="lastMore" className="Pagination-button notActive" disabled size={buttonSize}>
                …
              </Button>
              <Button
                className="Pagination-button"
                data-test-id={`pagination-current-${maxPages}`}
                key={maxPages}
                onClick={changePageCallback(maxPages - 1)}
                size={buttonSize}
              >
                {maxPages}
              </Button>
            </>
          )}

          {(!hideDisabledControls || currentPage !== maxPages - 1) && (
            <>
              <ArrowButton
                className={isCompact ? 'ml-12' : 'ml-20'}
                data-test-id="pagination-next"
                disabled={currentPage === maxPages - 1}
                rightIcon={!isCompact}
                icon="chevron-right"
                key="next"
                kind="secondary"
                onClick={changePageCallback(currentPage + 1)}
                size={buttonSize}
                text={isCompact ? '' : t('views.pagination.next_button', { default: 'Next' })}
                tooltip={t('views.pagination.next_page', { default: 'Next page' })}
              />
              <ArrowButton
                className="ml-12"
                data-test-id="pagination-last"
                disabled={currentPage === maxPages - 1}
                icon="chevron-last"
                key="last"
                kind="secondary"
                onClick={changePageCallback(maxPages - 1)}
                size={buttonSize}
                tooltip={t('views.pagination.last_page', { default: 'Last page' })}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  className: RPT.string,
  current: RPT.number,
  disableAnchor: RPT.bool,
  changePage: RPT.func,
  children: RPT.any,
  isCompact: RPT.bool,
  maxPages: RPT.number,
  hideDisabledControls: RPT.bool,
  variant: RPT.oneOf(['classic', 'minified']),
};

export default Pagination;
