import React from 'react';
import { Heading, FbAdList } from '../index';
import { t } from '../../i18n';
import FeaturesOverviewTable from './FeaturesOverviewTable';

const FbCampaignOverview = ({ data }) => {
  const {
    organizationId,
    campaignSettingId,
    budgetPeriod,
    budgetSum,
    budgetEditLink,
    campaignOptimizationLabel,
    campaignOptimizationValue,
    campaignOptimizationEditLink,
    campaignBid,
    campaignBidEditLink,
    campaignCurrency,
    isCampaignSynced,
  } = data;
  return (
    <div>
      <Heading spacing={16}>{t('react.fb_advantage_plus.campaign_overview.heading', { default: 'Preview' })}</Heading>
      <FeaturesOverviewTable
        budgetPeriod={budgetPeriod}
        budgetSum={budgetSum}
        budgetEditLink={budgetEditLink}
        campaignOptimizationLabel={campaignOptimizationLabel}
        campaignOptimizationValue={campaignOptimizationValue}
        campaignOptimizationEditLink={campaignOptimizationEditLink}
        campaignBid={campaignBid}
        campaignBidEditLink={campaignBidEditLink}
        campaignCurrency={campaignCurrency}
        isCampaignSynced={isCampaignSynced}
      />
      <FbAdList
        organizationId={organizationId}
        campaignSettingId={campaignSettingId}
        showMenu={false}
        showItemsMenu={false}
        showItemsArrows
      />
    </div>
  );
};

export default FbCampaignOverview;
