import React from 'react';
import PropTypes from 'prop-types';
import { Input, SegmentedButton, Switch } from '../../../form';
import { EditableWrapper, EditablePopoverWrapper } from '../wrappers';
import { Row, Col, ColorPicker } from '../../../index';
import PlaceholderInput from '../../../../placeholders/Input';
import Select from '../../../form/Select';

const TextEditComponent = ({ children, onChange }) => (
  <div>
    <Input
      id="editText"
      name="editText"
      doNotUseInternalState
      focusOnRender
      value={children}
      onChange={({ target: { value } }) => onChange(value || '')}
    />
  </div>
);

TextEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const PlaceholderEditComponent = ({ children, onChange }) => (
  <div>
    <PlaceholderInput
      id="placeholderEdit"
      name="placeholderEdit"
      placeholdersWithData={children?.placeholders || []}
      onChange={({ target: { value } }) => onChange({ ...children, text: value || '' })}
      defaultValue={children?.text}
      stickyToBottom="32px"
    />
  </div>
);

PlaceholderEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const PlaceholderSelectComponent = ({ children, onChange }) => (
  <div>
    <Select
      id="placeholderSelect"
      name="placeholderSelect"
      onChange={({ target: { value } }) => onChange({ ...children, text: value || '' })}
      defaultValue={children?.text}
      value={children?.text}
      doNotUseInternalState
    >
      <option value="" />
      {children.placeholders.map(placeholder => {
        const placeholderTextShort = placeholder.text.substring(1, placeholder.text.length - 1);
        return (
          <option key={placeholder.text} value={placeholderTextShort}>
            {placeholder.name}
          </option>
        );
      })}
    </Select>
  </div>
);

PlaceholderSelectComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const AdtextLineEditComponent = ({ children, onChange }) => (
  <div>
    <Row>
      <Col>
        <PlaceholderInput
          id="placeholderEdit"
          name="placeholderEdit"
          placeholdersWithData={children?.placeholders || []}
          onChange={({ target: { value } }) => onChange({ ...children, text: value || '' })}
          defaultValue={children?.text}
        />
      </Col>
    </Row>
    <Row>
      {!children?.disableOverflow && (
        <Col shrink>
          <Switch
            value={children?.overflow}
            onChange={({ target: { value } }) => onChange({ ...children, overflow: value })}
          />
        </Col>
      )}
      {!children?.disablePinned && (
        <Col shrink>
          <SegmentedButton
            className="mt-4"
            value={children?.pinned}
            doNotUseInternalState
            onChange={({ target: { value } }) => onChange({ ...children, pinned: value || '' })}
            collection={[
              { value: 'not_pinned', label: '–' },
              { value: 'first_position', label: '1' },
              { value: 'second_position', label: '2' },
              { value: 'third_position', label: '3' },
            ]}
          />
        </Col>
      )}
    </Row>
  </div>
);

AdtextLineEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};
const AdtextLineSwitcherEditComponent = ({ children, onChange }) => (
  <div>
    <Row>
      <Col shrink>
        <Switch
          label={children?.mainSwitcher?.label}
          value={children[children?.mainSwitcher?.key]}
          onChange={({ target: { value } }) => onChange({ ...children, [children?.mainSwitcher?.key]: value })}
        />
      </Col>
    </Row>
    {children[children?.mainSwitcher?.key] && (
      <React.Fragment>
        {children?.colorPickers.length > 0 &&
          children.colorPickers.map(({ key }) => (
            <Row key={key}>
              <Col grow>
                <ColorPicker
                  doNotUseInternalState
                  id={`adtext-color-picker-${key}`}
                  name={`adtext-color-picker-${key}`}
                  value={children?.[key]}
                  showOnEnd
                  onChange={({ target: { value } }) => onChange({ ...children, [key]: value })}
                />
              </Col>
            </Row>
          ))}

        {children?.switchers.length > 0 &&
          children.switchers.map(({ key, label }) => (
            <Row key={key}>
              <Col shrink>
                <Switch
                  label={label}
                  value={children?.[key]}
                  onChange={({ target: { value } }) => onChange({ ...children, [key]: value })}
                />
              </Col>
            </Row>
          ))}

        {children?.texts.length > 0 &&
          children.texts.map(({ key, label, notEditable }) => (
            <Row key={key}>
              {!notEditable ? (
                <Input
                  id={`editText-${key}`}
                  name={`editText-${key}`}
                  label={label}
                  doNotUseInternalState
                  focusOnRender
                  value={children?.[key]}
                  onChange={({ target: { value } }) => onChange({ ...children, [key]: value || '' })}
                />
              ) : (
                `${label}: ${children[key]}`
              )}
            </Row>
          ))}
      </React.Fragment>
    )}
  </div>
);

AdtextLineSwitcherEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const MoneyEditComponent = ({ children, onChange, ...rest }) => (
  <Row>
    <Col grow>
      <Input
        {...rest}
        id="editText"
        name="editText"
        type="float"
        doNotUseInternalState
        focusOnRender
        value={children.money}
        onChange={({ target: { value } }) => onChange({ ...children, money: value })}
      />
    </Col>
    {children.currency && (
      <Col shrink className="pt-8">
        {children.currency}
      </Col>
    )}
  </Row>
);

MoneyEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const FloatEditComponent = ({ children, onChange, ...rest }) => (
  <div>
    <Input
      {...rest}
      id="editText"
      name="editText"
      type="float"
      doNotUseInternalState
      focusOnRender
      value={children}
      onChange={({ target: { value } }) => onChange(value)}
    />
  </div>
);

FloatEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const IntegerEditComponent = ({ children, onChange, ...rest }) => (
  <div>
    <Input
      {...rest}
      id="editText"
      name="editText"
      type="integer"
      doNotUseInternalState
      focusOnRender
      value={children}
      onChange={({ target: { value } }) => onChange(value)}
    />
  </div>
);

IntegerEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const LinkEditComponent = ({ children: { text }, onChange }) => (
  <div>
    <Input
      id="editLinkText"
      name="editLinkText"
      doNotUseInternalState
      focusOnRender
      value={text}
      onChange={({ target: { value } }) => onChange({ text: value })}
    />
  </div>
);

LinkEditComponent.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export const AdtextLineSwitcherEdit = EditableWrapper(EditablePopoverWrapper(AdtextLineSwitcherEditComponent));
export const AdtextLineEdit = EditableWrapper(EditablePopoverWrapper(AdtextLineEditComponent));
export const FloatEdit = EditableWrapper(EditablePopoverWrapper(FloatEditComponent));
export const IntegerEdit = EditableWrapper(EditablePopoverWrapper(IntegerEditComponent));
export const LinkEdit = EditableWrapper(EditablePopoverWrapper(LinkEditComponent));
export const MoneyEdit = EditableWrapper(EditablePopoverWrapper(MoneyEditComponent));
export const PlaceholderEdit = EditableWrapper(EditablePopoverWrapper(PlaceholderEditComponent));
export const PlaceholderSelect = EditableWrapper(EditablePopoverWrapper(PlaceholderSelectComponent));
export const TextEdit = EditableWrapper(EditablePopoverWrapper(TextEditComponent));
