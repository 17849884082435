import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import QueryHolder from '../graphql_provider/query_holder';
import { SmartSelect, Link, Button, Loader, SmallHeading, Tile, Row, Col, SystemIcon, Icon } from '../components';
import InfoBox from '../components/InfoBox';
import ErrorBox from '../components/ErrorBox';

const DEV_DRIVE_KEY = 'AIzaSyBFMjFNkEcO2tscCnmyXoF4wrw7pQbPRsI';

const QUERY_GOOGLE_DRIVE_TOKEN = gql`
  query OrganizationGoogleSpreadsheetToken($organizationId: BigInt!, $accountId: BigInt!) {
    organization(id: $organizationId) {
      id
      accountAccess(id: $accountId) {
        id
        googleDriveToken
      }
    }
  }
`;

const QUERY_GOOGLE_SPREADSHEET_SHEET = gql`
  query OrganizationGoogleSpreadsheetFiles($organizationId: BigInt!, $accountId: BigInt!, $spreadsheetId: String!) {
    organization(id: $organizationId) {
      id
      accountAccess(id: $accountId) {
        id
        googleSheet(spreadsheetId: $spreadsheetId) {
          id
          name
        }
      }
    }
  }
`;

const QUERY_GOOGLE_SPREADSHEET_SHEET_COLUMNS = gql`
  query OrganizationGoogleSpreadsheetFiles(
    $organizationId: BigInt!
    $accountId: BigInt!
    $spreadsheetId: String!
    $sheetName: String!
  ) {
    organization(id: $organizationId) {
      id
      accountAccess(id: $accountId) {
        id
        googleSheetColumn(spreadsheetId: $spreadsheetId, sheetName: $sheetName) {
          name
        }
      }
    }
  }
`;

const NotOwnerWarning = ({ text }) => (
  <Row center>
    <InfoBox type="info" withIcon className="mv-8">
      {text}
    </InfoBox>
  </Row>
);

NotOwnerWarning.propTypes = {
  text: PropTypes.string.isRequired,
};

const GoogleFilePicker = ({
  i18n,
  organizationId,
  accountId,
  filePickerCallback,
  spreadsheetId,
  spreadsheetName,
  hasAccessError,
  handleTokenError,
}) => {
  const [gapiLoading, setGapiLoading] = useState(true);
  const [tokenEmpty, setTokenEmpty] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('picker', () => {
        setGapiLoading(false);
      });
    };
    // eslint-disable-next-line no-console
    script.onerror = console.error;
    document.head.appendChild(script);
  }, []);

  const { data, loading, error: tokenError } = useQuery(QUERY_GOOGLE_DRIVE_TOKEN, {
    variables: {
      organizationId: parseInt(organizationId, 10),
      accountId: parseInt(accountId, 10),
    },
  });

  useEffect(() => {
    if (!hasAccessError) {
      if (tokenError) {
        handleTokenError(true);
      }

      if (!tokenError && !data?.organization?.accountAccess?.googleDriveToken) {
        setTokenEmpty(true);
      } else setTokenEmpty(false);
    }
  }, [data, tokenError]);

  if (gapiLoading || loading) {
    return <Loader />;
  }

  const openFilePicker = () => {
    if (tokenEmpty) {
      return;
    }
    const picker = new window.google.picker.PickerBuilder()
      .addView(window.google.picker.ViewId.SPREADSHEETS)
      .setOAuthToken(data.organization.accountAccess.googleDriveToken)
      .setDeveloperKey(DEV_DRIVE_KEY)
      .setCallback(filePickerCallback)
      .build();
    picker.setVisible(true);
  };

  const setTestingRefCallback = el => {
    if (el) {
      el.filePickerCallback = filePickerCallback; // eslint-disable-line no-param-reassign
    }
  };

  if (spreadsheetName || spreadsheetId) {
    return (
      <Tile>
        <Row center>
          <Col shrink>
            <SystemIcon system="google_drive" size="24" />
          </Col>
          <Col grow>
            <Link
              className="d-inline-block"
              href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}`}
              target="_blank"
              rel="noopener noreferrer"
              external
            >
              {spreadsheetName || spreadsheetId}
            </Link>
          </Col>
          {!tokenEmpty && (
            <Col shrink>
              <Button
                data-test-id="change-google-drive"
                type="button"
                kind="secondary"
                onClick={openFilePicker}
                setRef={setTestingRefCallback}
                disabled={hasAccessError}
              >
                {i18n.change_file}
              </Button>
            </Col>
          )}
        </Row>
        {!hasAccessError && !tokenError && tokenEmpty && <NotOwnerWarning text={i18n.no_access_contact_owner} />}
      </Tile>
    );
  }
  return (
    <Tile onClick={openFilePicker}>
      <Row center>
        <Col shrink>
          <SystemIcon system="google_drive" size="24" />
        </Col>
        <Col grow>{i18n.select_file_info}</Col>
        {!tokenEmpty && (
          <Col shrink>
            <Button data-test-id="select-from-google-drive" type="button" clear primary setRef={setTestingRefCallback}>
              {i18n.select_from_google_drive}
            </Button>
          </Col>
        )}
      </Row>
      {!hasAccessError && !tokenError && tokenEmpty && <NotOwnerWarning text={i18n.no_access_contact_owner} />}
    </Tile>
  );
};

GoogleFilePicker.propTypes = {
  i18n: PropTypes.object.isRequired,
  organizationId: PropTypes.string,
  accountId: PropTypes.string,
  filePickerCallback: PropTypes.func.isRequired,
  spreadsheetId: PropTypes.string,
  spreadsheetName: PropTypes.string,
  hasAccessError: PropTypes.bool,
  handleTokenError: PropTypes.func,
};

const getIdFromFieldName = name => name.replace(/\[|\]/g, '_').replace(/_{2,}/g, '_');

const QuerySelect = QueryHolder(
  ({ addon, children, data, onChange, name, value, getCollection, i18n, translatedName, error, disabled }) =>
    !getCollection(data) ? (
      value && <input type="hidden" name={name} value={value} />
    ) : (
      <div className={`Input ${error && 'has-error'} mvm`}>
        {translatedName?.length > 0 && (
          <label className="Input-label select optional" htmlFor={getIdFromFieldName(name)}>
            {translatedName}
          </label>
        )}
        <SmartSelect
          name={name}
          id={getIdFromFieldName(name)}
          collection={getCollection(data).map(children)}
          data-test-id={getIdFromFieldName(name)}
          onChange={onChange}
          value={value}
          placeholder={i18n.please_select}
          initWithoutSelectedValue
          disabled={disabled}
        />
        {!error && addon}
        {error && <span className="Input-errorLabel">{error}</span>}
      </div>
    )
);

class GoogleSpreadseheetSelection extends React.Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    accountId: PropTypes.string,
    spreadsheetId: PropTypes.string,
    spreadsheetName: PropTypes.string,
    sheetName: PropTypes.string,
    sheetId: PropTypes.string,
    idColumn: PropTypes.string,
    errors: PropTypes.object,
    prefix: PropTypes.string,
    disableIdColumn: PropTypes.boolean,
    textWithCheck: PropTypes.string,
    textWithCheckNumber: PropTypes.string,
    hasAccessError: PropTypes.bool,
  };

  static defaultProps = {
    prefix: 'data_source',
  };

  state = {
    accountId: this.props.accountId || null,
    spreadsheetId: this.props.spreadsheetId || '',
    spreadsheetName: this.props.spreadsheetName || '',
    sheetName: this.props.sheetName || '',
    sheetId: this.props.sheetId || '',
    idColumn: this.props.idColumn || '',
    tokenError: false,
  };

  componentDidMount = () => {
    const accountElement = document.getElementById('react-select-google-account');
    if (accountElement) {
      accountElement.addEventListener('change', this.handleAccountChange);
    } else {
      setTimeout(() => {
        const accountElementAsync = document.getElementById('react-select-google-account');
        if (accountElementAsync) {
          accountElementAsync.addEventListener('change', this.handleAccountChange);
        }
      }, 500);
    }
  };

  handleAccountChange = ({ target: { value } }) => {
    if (this.state.accountId !== value) {
      this.setState({
        accountId: value,
        spreadsheetId: '',
        sheetName: '',
        sheetId: '',
        idColumn: '',
        tokenError: false,
      });
    }
  };

  filePickerCallback = data => {
    if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
      const doc = data[window.google.picker.Response.DOCUMENTS][0];
      this.setState({
        spreadsheetId: doc.id,
        spreadsheetName: doc.name,
        sheetId: '',
        sheetName: '',
        idColumn: '',
        tokenError: false,
      });
    }
  };

  handleTokenError = value => {
    this.setState({ ...this.state, tokenError: value });
  };

  render() {
    const {
      organizationId,
      i18n,
      errors,
      prefix,
      disableIdColumn,
      textWithCheck,
      textWithCheckNumber,
      hasAccessError,
    } = this.props;
    const { accountId, spreadsheetId, spreadsheetName, sheetName, sheetId, idColumn, tokenError } = this.state;

    if (!accountId || accountId.length === 0) {
      return null;
    }

    if (!hasAccessError && tokenError) {
      return (
        <ErrorBox withIcon isComplex>
          <span>{i18n.token_error}</span>
          <Button red className="mt-4" href={`/organizations/${organizationId}/account_accesses/${accountId}`}>
            {i18n.reconnect}
          </Button>
        </ErrorBox>
      );
    }

    const hasStateText = textWithCheck?.length > 0 && textWithCheckNumber?.length > 0;
    const isChecked = !disableIdColumn ? idColumn?.length > 0 : spreadsheetId?.length > 0;

    return (
      <Tile>
        <SmallHeading>
          {hasStateText ? (
            <>
              {isChecked && <Icon kind="active" size="14px" color="#63bc26" className="mr-4" />} {textWithCheck}
            </>
          ) : (
            i18n.headline
          )}
        </SmallHeading>
        <GoogleFilePicker
          i18n={i18n}
          filePickerCallback={this.filePickerCallback}
          accountId={accountId}
          organizationId={organizationId}
          spreadsheetId={spreadsheetId}
          spreadsheetName={spreadsheetName}
          hasAccessError={hasAccessError}
          handleTokenError={this.handleTokenError}
        />

        {spreadsheetId && spreadsheetId.length > 0 && (
          <QuerySelect
            showChildrenWhileLoading
            onChange={({ target: { value } }) => {
              const [id, name] = value.split('❤️', 2);
              this.setState({ sheetId: id, sheetName: name, idColumn: '' });
            }}
            query={QUERY_GOOGLE_SPREADSHEET_SHEET}
            value={[sheetId, sheetName].join('❤️')}
            name={`${prefix}[google_sheet_selector]`}
            getCollection={data => data?.organization?.accountAccess?.googleSheet}
            variables={{
              organizationId: parseInt(organizationId, 10),
              accountId: parseInt(accountId, 10),
              spreadsheetId,
            }}
            i18n={i18n}
            error={errors.google_sheet_name}
            translatedName={i18n.google_sheet_name}
            disabled={hasAccessError}
          >
            {({ id, name }) => ({ label: name, value: [id, name].join('❤️') })}
          </QuerySelect>
        )}
        {!disableIdColumn && sheetId && sheetId.length > 0 && (
          <>
            <QuerySelect
              showChildrenWhileLoading
              onChange={({ target: { value } }) => this.setState({ idColumn: value })}
              value={idColumn}
              name={`${prefix}[unique_column]`}
              query={QUERY_GOOGLE_SPREADSHEET_SHEET_COLUMNS}
              getCollection={data => data?.organization?.accountAccess?.googleSheetColumn}
              variables={{
                organizationId: parseInt(organizationId, 10),
                accountId: parseInt(accountId, 10),
                spreadsheetId,
                sheetName,
              }}
              i18n={i18n}
              error={errors.unique_column}
              translatedName={i18n.unique_column}
              disabled={hasAccessError}
            >
              {({ name }) => ({ label: name, value: name })}
            </QuerySelect>
          </>
        )}
        {
          <>
            <input type="hidden" name={`${prefix}[google_sheet_id]`} value={sheetId} />
            <input type="hidden" name={`${prefix}[google_sheet_name]`} value={sheetName} />
            <input type="hidden" name={`${prefix}[google_spreadsheet_id]`} value={spreadsheetId} />
            <input type="hidden" name={`${prefix}[google_spreadsheet_name]`} value={spreadsheetName} />
          </>
        }
      </Tile>
    );
  }
}

export default GoogleSpreadseheetSelection;
